import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "./homepage.css";
import Login from "./login";
import TimeLine from "./timelines";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import BackToTop from "react-back-to-top-button";
import { BiArrowFromBottom } from "react-icons/bi";
class Homepage extends React.Component {
  state = {
    name: null, // user name to display in the navbar, this is from redis client
    username: null, // user login input field name
    password: null,
    rememberMe: false,
    loginSuccess: false,
    message: "",
  };

  componentDidMount = () => {
    if (localStorage.getItem("remember") == "true") {
      var token = { token: localStorage.getItem("token") };
      axios
        .post("api/loginRoute/loginToken", token)
        .then((res) => {
          if (res.data.success) {
            this.setState({ loginSuccess: true, name: res.data.name });
          } else {
            this.setState({ loginSuccess: false });
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  };
  rememberMeCheckbox = (e) => {
    const checked = e.target.checked;
    if (checked) {
      this.setState({ rememberMe: true });
    } else {
      this.setState({ rememberMe: false });
    }
  };
  tryLogin = (obj) => {
    axios
      .post("/api/loginRoute/login", obj)
      .then((res) => {
        if (res.data.success == true) {
          this.setState({ name: obj.username });
          if (this.state.rememberMe) {
            localStorage.setItem("remember", true);
            localStorage.setItem("token", res.data.token);
          }
        }
        this.setState({
          loginSuccess: res.data.success,
          message: res.data.message,
        });
      })
      .catch((err) => {
        console.log("error of catch:", err);
      });
  };
  submitHandler = (e) => {
    e.preventDefault();
  };
  updateLoginSession = (status) => {
    this.setState({ loginSuccess: status });
    localStorage.removeItem("remember");
    localStorage.removeItem("token");
  };
  loginSection = () => {
    return (
      <div className="Login">
        <div className="loginHeader">
          <h3>Please login to access the content</h3>
        </div>
        <Form onSubmit={this.submitHandler}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Username: </Form.Label>
            <Form.Control
              type="username"
              placeholder="Enter username"
              onChange={(e) => this.setState({ username: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Remember me"
              onClick={(e) => {
                this.rememberMeCheckbox(e);
              }}
            />
          </Form.Group>
          <div className="login-message">{this.state.message}</div>
          <Button
            variant="outline-primary"
            type="submit"
            onClick={() =>
              this.tryLogin({
                username: this.state.username,
                password: this.state.password,
                rememberMe: this.state.rememberMe,
              })
            }
          >
            Login
          </Button>
        </Form>
      </div>
    );
  };
  render() {
    return (
      <div>
        {!this.state.loginSuccess ? (
          this.loginSection()
        ) : (
          <TimeLine
            updateLoginStatus={this.updateLoginSession}
            username={this.state.name}
          />
        )}
        <BackToTop speed={2000} showAt={1000} showOnScrollUp={true}>
          <BiArrowFromBottom
            style={{
              background: "white",
              borderRadius: "50%",
              color: `rgb(128,0,128)`,
            }}
          />
        </BackToTop>
      </div>
    );
  }
}
export default Homepage;
