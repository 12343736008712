import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import "./login.css";
import axios from "axios";

class Login extends Component {
  state = {
    username: null,
    password: null,
    rememberMe: false,
  };

  componentDidMount = () => {
    if (localStorage.getItem("remember") != null) {
      this.setState({ rememberMe: true });
    }
  };

  rememberMeCheckbox(e) {
    const checked = e.target.checked;
    if (checked) {
      this.setState({ rememberMe: true });
    } else {
      this.setState({ rememberMe: false });
    }
  }

  tryLogin = (obj) => {
    axios
      .post("/api/loginRoute/login", obj)
      .then((res) => {
        this.props.data.loginSuccess = res.data.success;
        if (this.state.rememberMe) {
          this.setState({ name: this.state.username });
          localStorage.setItem("remember", true);
        }
      })
      .catch((err) => {
        console.log("error of login:", err);
      });
  };

  submitHandler = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <div className="Login">
        <div className="loginHeader">
          <h4>Please log in to access the content</h4>
        </div>
        <Form onSubmit={this.submitHandler}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Username: </Form.Label>
            <Form.Control
              type="username"
              placeholder="Enter username"
              onChange={(e) => this.setState({ username: e.target.value })}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              id="remeberMe"
              type="checkbox"
              label="Remember me"
              onChange={(e) => this.rememberMeCheckbox(e)}
            />
          </Form.Group>
          <Button
            variant="outline-primary"
            type="submit"
            onClick={() =>
              this.tryLogin({
                username: this.state.username,
                password: this.state.password,
                rememberMe: this.state.rememberMe,
              })
            }
          >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

export default Login;
