import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import BrooklynPic from "./brooklyn-pic.png";
import "./timelines.css";
import {
  Container,
  Navbar,
  Modal,
  Button,
  Form,
  ProgressBar,
} from "react-bootstrap";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import moment from "moment";
import axios from "axios";
import CountUp from "react-countup";

class TimeLine extends React.Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      deleteModal: false,
      newPostModal: false,
      editPostModal: false,
      title: "",
      location: "",
      summary: "",
      date: "",
      errorField: "",
      deleteID: null,
      editPostID: null,
      editPostIndex: 0,
      selectedFile: null,
      fileUploadProgress: null,
    };
  }

  componentDidMount() {
    axios.get("/api/getRoute/getPost").then((res) => {
      this.setState({ posts: res.data.posts });
    });
  }

  loginUserNameFormat = (username) => {
    var username = username.toLowerCase();
    return username.charAt(0).toUpperCase() + username.slice(1);
  };

  dateTogether = () => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const metDate = new Date(2022, 2, 19);
    const currentDate = new Date();

    const diffDays = Math.round(Math.abs((currentDate - metDate) / oneDay));
    return diffDays;
  };

  logout = () => {
    this.props.updateLoginStatus(false);
  };

  deletePost = (data) => {
    axios.post("/api/postRoute/deletePost", data).then((res) => {
      if (res.data.success) {
        axios.get("/api/getRoute/getPost").then((res) => {
          this.setState({ posts: res.data.posts });
          this.updateModalVisibility({
            stateName: "deleteModal",
            visibility: false,
          });
        });
      }
    });
  };

  updateModalVisibility = (data) => {
    this.setState({
      [data.stateName]: data.visibility,
      errorField: "",
      deleteID: data.deleteID,
      selectedFile: null,
      editPostID: data.postID,
      editPostIndex: data.editPostIndex,
    });
  };

  onFileChange = (e) => {
    if (e.target.files.length > 0 && e.target.files[0].size <= 888000000) {
      this.setState({ selectedFile: e.target.files[0], errorField: "" });
    } else {
      this.setState({
        errorField: "please select file that is less than 888MB.",
      });
    }
  };

  checkValidDateRange = (data) => {
    var selectedDate = data.replace(/-/g, "");
    var minDate = "20220319";
    var maxDate = moment().format("YYYY-MM-DD").replace(/-/g, "");

    if (selectedDate < minDate) {
      return "Please select a date that is greater than 2022-03-19";
    } else if (selectedDate > maxDate) {
      return "Please select a date that is less than today";
    } else {
      return null;
    }
  };

  submitPost = (data) => {
    if (
      data.title.trim().length == 0 ||
      data.summary.trim().length == 0 ||
      data.date.trim().length == 0
    ) {
      this.setState({ errorField: "Please fill in all required fields" });
    } else if (this.checkValidDateRange(data.date) != null) {
      this.setState({ errorField: this.checkValidDateRange(data.date) });
    } else {
      this.setState({ errorField: "" });

      const formData = new FormData();
      formData.append("myFiles", this.state.selectedFile);

      axios
        .post("/api/postRoute/upload", formData, {
          onUploadProgress: (progressEvent) => {
            this.setState({
              fileUploadProgress: Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              ),
            });
          },
        })
        .then((imageUploadResult) => {
          if (imageUploadResult.data.success) {
            data.fileLocation = imageUploadResult.data.imageLocation;
            axios
              .post("/api/postRoute/postMoment", data)
              .then((postMomentResult) => {
                if (postMomentResult.data.success) {
                  axios
                    .get("/api/getRoute/getPost")
                    .then((res) => {
                      this.updateModalVisibility({
                        stateName: "newPostModal",
                        visibility: false,
                      });
                      this.setState({
                        posts: res.data.posts,
                        fileUploadProgress: null,
                        title: "",
                        location: "",
                        summary: "",
                        date: "",
                        editPostModal: false,
                      });
                    })
                    .catch((err) => {
                      alert(
                        "something went wrong while retriving posts, please let Jack know!"
                      );
                    });
                } else {
                  alert(
                    "something went wrong while submitting a post, please let Jack know!"
                  );
                }
              });
          } else {
            alert(
              "something went wrong while uploading file, please let Jack know!"
            );
          }
        })
        .catch((err) => {
          alert(
            "something went wrong while uploading image, please let Jack know!"
          );
          console.log("error while uploading image: ", err);
        });
    }
  };

  CheckMediaType = (file) => {
    let imageType = ["png", "jpg", "gif", "jpeg"];
    let videoType = ["mp4", "mov", "ogg"];
    const fileType = file.substring(file.length - 3, file.length).toLowerCase();
    const fileTypeCornerCase = file
      .substring(file.length - 4, file.length)
      .toLowerCase();

    if (
      imageType.includes(fileType) ||
      imageType.includes(fileTypeCornerCase)
    ) {
      return <img className="post-item-main-image" src={file} alt="" />;
    }
    if (videoType.includes(fileType)) {
      return (
        <video
          className="post-item-main-image"
          playsInline
          // src={file}
          controls
          preload="metadata"
          src={file + "#t=0.1"}
        ></video>
      );
    }
    return "unkown";
  };

  editPopup = () => {
    try {
      var editPostTitle = this.state.posts[this.state.editPostIndex].title;
      var editPostLocation =
        this.state.posts[this.state.editPostIndex].location;
      var editPostSummary = this.state.posts[this.state.editPostIndex].summary;
      var editPostDate = this.state.posts[
        this.state.editPostIndex
      ].date.substring(0, 10);
    } catch (Error) {
      var editPostTitle = "";
      var editPostLocation = "";
      var editPostSummary = "";
      var editPostDate = "";
    }

    return (
      <div>
        {/* Edit a current post */}
        <Modal
          show={this.state.editPostModal}
          onHide={() =>
            this.updateModalVisibility({
              stateName: "editPostModal",
              visibility: false,
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Update current post</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Title<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Required field"
                  defaultValue={editPostTitle}
                  onChange={(e) => (editPostTitle = e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Optional field"
                  onChange={(e) => (editPostLocation = e.target.value)}
                  defaultValue={editPostLocation}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Summary<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Required field"
                  rows={3}
                  onChange={(e) => (editPostSummary = e.target.value)}
                  defaultValue={editPostSummary}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Date
                  <span style={{ fontSize: "12px" }}>
                    (old date: {editPostDate})
                  </span>
                </Form.Label>
                <br />
                <input
                  id="editDateInput"
                  type="date"
                  min="2022-03-19"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) => (editPostDate = e.target.value)}
                />
              </Form.Group>

              <span style={{ color: "red" }}>{this.state.errorField}</span>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={() =>
                this.updateModalVisibility({
                  stateName: "editPostModal",
                  visibility: false,
                })
              }
            >
              Close
            </Button>
            <Button
              variant="outline-primary"
              onClick={() =>
                this.submitPost({
                  title: editPostTitle,
                  location: editPostLocation,
                  summary: editPostSummary,
                  date: editPostDate,
                  fileURL:
                    this.state.posts[this.state.editPostIndex].fileLocation,
                  userName: this.loginUserNameFormat(this.props.username),
                  editPostID: this.state.posts[this.state.editPostIndex]._id,
                })
              }
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  render() {
    return (
      <div style={{ whiteSpace: "pre-wrap" }}>
        {/* Delete confirmation pop up */}
        <Modal
          className="pop-up"
          show={this.state.deleteModal}
          onHide={() =>
            this.updateModalVisibility({
              stateName: "deleteModal",
              visibility: false,
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure to delete this post?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={() =>
                this.updateModalVisibility({
                  stateName: "deleteModal",
                  visibility: false,
                })
              }
            >
              Close
            </Button>
            <Button
              variant="outline-danger"
              onClick={() =>
                this.deletePost({
                  postID: this.state.deleteID,
                })
              }
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.editPostModal ? this.editPopup() : null}

        {/* Add a new post pop up */}
        <Modal
          show={this.state.newPostModal}
          onHide={() =>
            this.updateModalVisibility({
              stateName: "newPostModal",
              visibility: false,
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Please input information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Title<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Required field"
                  onChange={(e) => this.setState({ title: e.target.value })}
                  defaultValue={this.state.title}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Optional field"
                  onChange={(e) => this.setState({ location: e.target.value })}
                  defaultValue={this.state.location}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Summary<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Required field"
                  rows={3}
                  onChange={(e) => this.setState({ summary: e.target.value })}
                  defaultValue={this.state.summary}
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Date<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <br />
                <input
                  type="date"
                  min="2022-03-19"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) => this.setState({ date: e.target.value })}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>File</Form.Label>
                <br />
                <input
                  id="file"
                  type="file"
                  accept="video/*,image/*"
                  name="selectedFile"
                  onChange={this.onFileChange}
                />
              </Form.Group>
              <br />
              {this.state.fileUploadProgress != null &&
              this.state.fileUploadProgress <= 100 ? (
                <div style={{ width: "100%" }}>
                  Uploading file:
                  <ProgressBar
                    label={
                      this.state.fileUploadProgress == 100
                        ? "Almost done"
                        : this.state.fileUploadProgress + "%"
                    }
                    animated
                    now={this.state.fileUploadProgress}
                  />
                </div>
              ) : (
                <div style={{ textDecorationLine: "underline" }}>
                  {this.state.message}
                </div>
              )}
              <span style={{ color: "red" }}>{this.state.errorField}</span>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={() =>
                this.updateModalVisibility({
                  stateName: "newPostModal",
                  visibility: false,
                })
              }
            >
              Close
            </Button>
            <Button
              variant="outline-primary"
              onClick={() =>
                this.submitPost({
                  title: this.state.title,
                  location: this.state.location,
                  summary: this.state.summary,
                  date: this.state.date,
                  file: this.state.selectedFile,
                  userName: this.loginUserNameFormat(this.props.username),
                })
              }
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Navbar className="color-nav">
          <Container>
            <Navbar.Brand>
              Welcome{" "}
              <strong>{this.loginUserNameFormat(this.props.username)}</strong>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <a href="" onClick={() => this.logout()}>
                  Logout
                </a>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="header">
          <img src={BrooklynPic} className="header-photo-style" />
          {/* <img src={JJC} class="photo-style" alt="Jack Chen"/>
        <div className="imgDiv"> </div>
        <img src={KC} className="photo-style" alt="Kristi Chan"/> */}
          <h2>
            <CountUp end={this.dateTogether()} duration={3} /> days
          </h2>
          <AwesomeButton
            type="secondary"
            onPress={() =>
              this.updateModalVisibility({
                stateName: "newPostModal",
                visibility: true,
              })
            }
          >
            Add a new post
          </AwesomeButton>
        </div>

        <VerticalTimeline>
          {this.state.posts.map((post, index) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#ebebed", color: "black" }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(33, 150, 243)",
              }}
              date={post.date.substring(0, 10)}
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              icon={<AccessAlarmIcon />}
            >
              <h5 className="vertical-timeline-element-title">
                <strong>
                  <em>
                    <u>Title</u>:{" "}
                  </em>
                </strong>{" "}
                {post.title}
              </h5>
              <h5 className="vertical-timeline-element-subtitle">
                <strong>
                  <em>
                    <u>Location</u>:{" "}
                  </em>
                </strong>{" "}
                {post.location}{" "}
              </h5>
              <br />

              {post.fileLocation != null
                ? this.CheckMediaType(post.fileLocation)
                : null}

              <p>
                <strong>
                  <em>
                    <u>Summary</u>:{" "}
                  </em>
                </strong>
                <br />
                {post.summary}
              </p>
              <p style={{ fontSize: "14px" }}>
                <strong>
                  <em>
                    <u>By</u>:{" "}
                  </em>
                </strong>
                {post.userName}
                <DeleteIcon
                  className="delete-icon"
                  onClick={() =>
                    this.updateModalVisibility({
                      stateName: "deleteModal",
                      visibility: true,
                      deleteID: post._id,
                    })
                  }
                />

                <EditIcon
                  className="edit-icon"
                  onClick={() =>
                    this.updateModalVisibility({
                      stateName: "editPostModal",
                      visibility: true,
                      editPostID: post._id,
                      editPostIndex: index,
                    })
                  }
                />
              </p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    );
  }
}

export default TimeLine;
